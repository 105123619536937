@import "../../../styles/mixins.scss";
@import "styles/colors.module.scss";

.issueElement{
    display: flex;
    flex-direction: row;
    padding: rem(8);
    gap: rem(4);
    cursor: pointer;

    &:hover {
        background-color: $secondary-light;
    }
    .thumbnail{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .info{
        display: flex;
        flex-direction: column;
        width: 100%;

        .title{
            display: flex;
            flex-direction: row;
            width: 100%;

            .state {
                padding: rem(4) rem(16);
                font-weight: 500;
                text-transform: capitalize;
                border-radius: rem(20);
                width: fit-content;
                margin-left: auto;
                font-size: rem(14);
                width: rem(24);
                height: rem(24);
                padding: 0;
                display: flex;
                text-align: center;
                vertical-align: middle;
                justify-content: center;
                align-items: center;
            
                > span {
                    margin: auto;
                }

                &.unset{
                    background-color: $state-closed;
                    color: $white;
                }

                &.OPEN{
                    background-color: $state-open;
                    color: $white;
                }

                &.ACTIVE{
                    background-color: $state-active;
                    color: $white;
                }

                &.IN_PROGRESS{
                    background-color: $state-active;
                    color: $white;
                }

                &.RESOLVED{
                    background-color: $state-resolved;
                    color: $white;
                }

                &.ON_HOLD{
                    background-color: $state-onhold;
                    color: $text-secondary;
                }
            }
        }

        .assigned{
            margin-top: auto;
            
            width: 100%;
            display: flex;
            margin-top: auto;
        }
    }

   
}