.sidebar-root-container{
    transition-property: inset top bottom;
    transition-timing-function: ease-in-out;
    transition-duration: 0.25s;
    position: fixed;

    .sidebar-root-indicator{
        height: 100%;
        transition: width 0.5s;

        &.left{
            margin-right: auto;
        }

        &.right{
            margin-left: auto;
        }
    }   
}