@import "styles/mixins.scss";
@import "styles/colors.module.scss";

.importset-dialog {
    padding-right: 0 !important;
    

    .header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        width: 100%;
        height: rem(35);
    }

    .body-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: rem(15);
        width: 100%;
        height: rem(575);
        padding-right: rem(35) !important;

        .body-image {
            width: 100%;
            height: auto;
            background: $blue;
            border-radius: rem(5);
        }

        .body-input {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0;
            gap: 0;
            width: 100%;

            .form-input {
                width: 100%;

                div {
                    height: auto;
                }
            }

            .label-input-required {
                color: $error-dark;
                padding-left: rem(5);
            }

            .form-input-description {
                width: 100%;
            }
        }

    }

    .footer-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        width: 100%;
        height: rem(55);

        padding-right: rem(35) !important;

        .form-input {
            width: 100%;
        }
    }
}