@import "../../../styles/mixins.scss";
@import "styles/colors.module.scss";

.issueCard {
    cursor: pointer;
    padding: rem(6);
    padding-right: 3%;
    overflow: hidden;
    max-width: 100%;
    position: relative; 
    justify-content: start;   
    text-align: start;
    border-bottom: 1px solid rgba(0, 0, 0, 0.27);
    display: grid !important;
    grid-template-areas:
        "thumbnail title state"
        "thumbnail description description"
        "thumbnail assigned assigned" !important;
   
    column-gap: 2%;
    grid-template-rows: auto auto auto;
    grid-template-columns: auto auto 5%;
    border-radius: 0px !important;

    &:hover {
        background-color: $secondary-light;
    }
}

.sidePanel {
    width: rem(325) !important;
}

.issueIndex {
    grid-area: index;
}

.issueTitle {
    grid-area: title;
}

.stateChip {
    grid-area: state;
    padding: rem(4) rem(16);
    font-weight: 500;
    text-transform: capitalize;
    border-radius: rem(20);
    width: fit-content;
    margin-left: auto;
    font-size: rem(14);
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0 auto;
    display: flex;
    text-align: center;
    vertical-align: middle;
    justify-content: center;
    align-items: center;

    > span {
        margin: auto;
    }
}

.issueDescription {
    grid-area: description;
}

.issueAssigned {
    grid-area: assigned;
    white-space: nowrap;
}

.thumbnail {
    grid-area: thumbnail;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.list {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.no-issues{
    margin: auto;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    *{
        font-style: italic;
        color: rgba(0,0,0,0.24);
    }
}