@import "styles/mixins.scss";
@import "styles/colors.module.scss";

.delete-dialog-content-container {
    p {
        font-weight: 400;
    }

    .verification-container {
        margin-top: rem(10);

        .verification{
            font-weight: 700;
        }
    }
}