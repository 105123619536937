.dialog > div > ul{
    margin-top: 0;
    padding-top: 0 !important;
    height: fit-content;
    max-height: 90vh;
}

.root{
    pointer-events: none;
    margin-top: 10px;
}

.paper{
    pointer-events: all;
}



.categoryTitle{
    background-color: #eeeeee !important;
}

.issueCard{
    grid-template-areas: 
        'thumbnail title state'
        'thumbnail project project'
        'thumbnail assignee assignee'
    ;
}

.noResults{
    width: 603px;
    height: 72px;
}

.itemCard{
    display: grid;
    grid-template-columns: 128px 275px 100px;
    grid-template-rows: 32px 20px 20px;
    column-gap: 10px;
}

.item{
    width: 100%;
    padding: 4px;
}

.itemLink{
    width: 100%;
}

.scrollbar{
    max-height: calc(100vh - 100px);
    height: fit-content;
}

.thumbnail{
    width: 127px;
    height: 72px;
    grid-area: thumbnail;
}
.thumbnailIcon{
    width: 100% !important;
    height: 100% !important;
    font-size: 50% !important;
}


.issueTooltip{
    font-size: large;
}
.issueTitle{
    grid-area: title;
}

.issueAssignee{
    grid-area: assignee;
}

.issueProject{
    grid-area: project;
}

.issueState{
    float: right;
    padding-right: 5px;
    padding-left: 5px;
    grid-area: state;
    border-radius: 8px;
}

.issueActive{
    background-color: rgb(164, 173, 164);
}

.issueResolved{
    background-color: rgb(164, 173, 164);
}

.projectCard{
    grid-template-areas: 
        'thumbnail title state'
        'thumbnail team team'
        'thumbnail updated updated'
    ;
}

.stateChip{
    width: fit-content;
    margin-left: auto;
}

.projectTitle{
    grid-area: title;
}

.projectTeam{
    grid-area: team;
}

.projectUpdate{
    grid-area: updated;
}