.closeButton {
    float: right;
    margin-left: auto !important;
}

.endSelect {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    width: 150px;
}

.endSelect > fieldset {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.scrollBox {
    max-height: 400px;
    overflow-y: scroll;
    margin-right: -15px;
    padding-right: 15px;
}