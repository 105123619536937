.new-account-container {
    width: 100vw;
    height: 100vh;
    margin: auto;
    display: flex;
    flex-direction: column;

    .new-account-content {
        height: 50%;
        width: 50%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .fetching-data-container {
            width: fit-content;
            display: flex;
            white-space: nowrap;
            text-overflow: clip;
        }

        .info-container {
            color: rgb(75, 75, 75);
            .error-text{
                color: rgb(243, 127, 127);
            }
        }
    }
}
