@import "styles/mixins.scss";
@import "styles/colors.module.scss";

.inline-text-form {
    display: flex;
    flex-direction: row;
    position: relative;
    color: inherit;
    font-weight: inherit;

    .inline-text-textfield {
        width: fit-content;
        font-size: inherit;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis !important;

        .inline-text-input {
            overflow: hidden;
            text-overflow: ellipsis !important;
        }

        &.inline-text-input-disabled {
            cursor: text;
            pointer-events: none;
        }

        ::before {
            display: none;
        }
    }

    .inline-text-input-area {
        font: inherit;
        outline: none !important;
        resize: vertical;
        border: 2px solid rgba(66, 165, 245, 1) !important;
        border-radius: 4px;
        width: 100%;
        cursor: text;
        font-weight: 400;

        input {
            color: initial;
        }

        &.inline-text-input-disabled {
            border: 2px solid transparent !important;
            cursor: text;
            pointer-events: none;
            resize: none;
            padding: 0;
        }

        &:nth-of-type(2) {
            pointer-events: all;
        }
    }

    .inline-text-action {
        padding: initial;
        margin: auto auto auto 0;
        font: inherit;

        svg {
            font-size: 150%;
        }
    }

    .action-container {
        display: flex;
        gap: rem(8);
        margin-top: rem(15);

        button {
            padding: 0 rem(16);
            font-size: rem(12);
            line-height: rem(24);
            border-color: $blue;
            text-transform: capitalize;
            box-shadow: 0px 2px 5px rgba(0, 42, 76, 0.15);

            &.inline-text-submit {
                background-color: $blue;
                border: 1px solid lighten($blue, 20%);

                &:disabled {
                    background-color: initial;
                }

                &:hover {
                    background-color: darken($blue, 10%);
                }
            }

            &.inline-text-cancel {
                color: #666;

                &:hover {
                    border-color: lighten($blue, 20%);
                    background-color: initial;
                }
            }
        }
    }

    .inline-textarea-edit {
        position: absolute;
        top: 0;
        right: 0;
    }

    &.inline-text-form-vertical {
        flex-direction: column;

        .inline-text-action {
            margin: 12px;
            padding: 4px 8px;
            width: fit-content;
        }

        .inline-text-edit {
            margin: 12px;
            width: fit-content;
        }
    }

    .inline-text-edit,
    .inline-textarea-edit {
        opacity: 0;
        padding: 0 rem(16);
        font-size: rem(12);
        line-height: rem(24);
        width: 1em;
    }

    &:hover {
        .inline-text-edit,
        .inline-textarea-edit {
            opacity: 1 !important;
        }
    }
}
