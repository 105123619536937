@import "../../../styles/mixins.scss";
@import "../../../styles/colors.module.scss";

.import-set-card {
    padding: 4px;
    gap: 0px;
    cursor: pointer;

    .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        height: 50px;
        background: linear-gradient(90deg, rgba(216, 216, 216, 0.15) 0%, rgba(216, 216, 216, 0.2) 100%);
        border-radius: 4px;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;

        &.selected {
            background: #F9AF0833;
        }
    }
    

}



.skeleton-text {
    width: 50px;
    margin-left: 8px;
}

.import-set-right-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: none;
    order: 1;
    flex-grow: 0;
    gap: 5px;
}

.import-set-name-and-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;
    height: 18px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.import-set-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 5px;
    height: 12px;

    flex: none;
    order: 1;
    flex-grow: 0;
}

.import-set-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 125px;
}

.import-set-circle-icon {
    color: #B0B0C1;
    font-size: 5px;
}

.import-set-share-icon {
    color: $primary;
}

.import-set-secondary-text {
    color: #B0B0C1;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90px;
    white-space: nowrap;
}

.highlight {
    background-color: $primary-light;
}

.sidePanel {
    width: rem(325) !important;
    height: 100%;

    .list {
        padding-top: 4px !important;
        padding-bottom: 0 !important;

      
    }

    .no-sets{
        display: flex;
        flex-direction: column;
        font-style: italic;
        color: rgba(0,0,0,0.24);
        margin: auto;
        text-align: center;
    }
}

.thumbnail {
    grid-area: thumbnail;
    width: 70px;
    height: 35px;
    display: flex;
    margin-left: 6px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}


