@import "styles/mixins.scss";
@import "styles/colors.module.scss";

$animation: all ease-in-out 0.3s;

.dialog-root {
    z-index: 999;
    position: fixed;
    bottom: 0;
    right: 0;
    margin: rem(30);
    transition: $animation;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    .dialog-backdrop{
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        cursor: pointer;
    }

    &.dialog-closed {
        height: rem(36);
        width: rem(64);

        &:hover {
            width: rem(140);
        }

        .dialog-content {
            display: none;
        }

        .dialog-button {
            display: inline-flex;
            font-size: rem(16);
            transition: $animation;
            padding: rem(10) rem(20);
            justify-content: center;
            box-shadow: 0px 2px 5px rgba(0, 42, 76, 0.15);
            border-radius: rem(4);

            .dialog-button-text {
                opacity: 0;
                transition: $animation;
                width: 0;
                text-overflow: clip;
                text-transform: capitalize;
            }

            &:hover {
                width: rem(144);

                .dialog-button-text {
                    opacity: 1;
                    padding-left: 8px;
                    width: rem(90);
                }
            }
        }
    }

    &.dialog-open {
        height: rem(425);
        width: rem(430);

        &:has(.dialog-verification.visible) {
            height: rem(108);
            width: rem(334);
        }

        .dialog-button {
            display: none;
        }

        .dialog-content {
            width: 100%;
            height: 100%;
            justify-content: center;
            text-align: center;
            align-items: center;
            position: relative;
            overflow: hidden;

            form {
                height: 100%;

                .dialog-title {
                    font-weight: 700;
                    font-size: rem(36);
                    line-height: rem(42);
                    margin: 0 rem(50);
                }

                .dialog-subtitle {
                    font-weight: 400;
                    font-size: rem(14);
                    line-height: rem(16);
                }

                .dialog-input-label {
                    font-weight: 600;
                    font-size: rem(16);
                    line-height: rem(19);
                    margin-bottom: rem(9);
                    text-align: left;
                }

                .dialog-input {
                    transition: $animation;
                    display: flex;
                    flex-direction: column;
                    gap: rem(12);
                    padding: rem(16);

                    &.visible {
                        opacity: 1;
                    }

                    &.invisible {
                        opacity: 0;
                    }
                }

                .dialog-verification {
                    transition: $animation;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: fit-content;
                    height: fit-content;
                    justify-content: center;
                    margin: auto;

                    &.visible {
                        opacity: 1;
                    }

                    &.invisible {
                        opacity: 0;
                        pointer-events: none;
                    }
                }

                .dialog-mood-group {
                    border: 0;
                    margin-left: auto;
                    margin-right: auto;

                    .dialog-mood-toggle {
                        border: 0;
                        border-radius: 100%;
                        color: $text-disabled;
                        font-size: rem(48);
                        background: none;
                        transition: all ease-in-out 0.2s;

                        &:hover {
                            transform: scale(1.1);

                            &[value="sad"]{
                                color: $red-light;
                            }
                            &[value="meh"]{
                                color: $warning-light;
                            }
                            &[value="happy"]{
                                color: $green-light;
                            }
                        }

                        &.dialog-mood-toggle-selected {
                            &[value="sad"]{
                                color: $red;
                            }
                            &[value="meh"]{
                                color: $warning;
                            }
                            &[value="happy"]{
                                color: $green;
                            }
                        }
                    }
                }

                .submit-button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: rem(7) rem(20);
                    gap: rem(5);
                    box-shadow: 0px 2px 5px rgba(0, 42, 76, 0.15);
                    border-radius: rem(4);
                    width: fit-content;
                    text-transform: capitalize;
                }

                .dialog-input-text {
                    height: auto;
                    display: flex;
                    flex-direction: column;

                    .dialog-input-text-container {
                        width: 100%;

                        .dialog-input-textarea {
                            width: 100%;
                            height: initial;

                            > * {
                                height: initial;
                            }
                        }
                    }
                }

                .dialog-close-button {
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin: rem(15);
                    color: $icon;
                    width: rem(40);
                }
            }
        }
    }
}