@import "styles/mixins.scss";

.content-layout {
    display: grid;
    grid-template-columns: 75px 1fr 1fr 1fr 75px;
    grid-template-rows: 69.4px calc(100vh - 69.4px);
    grid-template-areas:
        "header header header header header"
        "main main main main main";
    gap: 0;
    width: 100%;
    height: 100vh;

    .main-middle {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.infinite-scroll,
.media-list {
    @include flex-grid(12, 0);

    .refreshPrompt {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        left: 50%;

        .refreshPromptText {
            text-align: center;
            vertical-align: middle;
            height: 100%;
            white-space: nowrap;
        }
    }

    .project-item,
    .project-item-skeleton {
        @include flex-grid-col(12, 12, 20);
        padding: 0;
        transition: all 0.3s;
        margin-bottom: rem(20);
    }

    .project-item-skeleton {
        height: rem(274);
        border-radius: rem(4);
    }

    .pageEnd {
        display: block;
        width: 100%;
        text-align: center;
    }
}

.loading-box {
    display: flex;
    width: 100%;

    > :first-child {
        width: 100%;
        margin: 0 8px;
        overflow: hidden;
        border-radius: 2px;
    }

    .loading-text {
        text-align: center;
        left: 50%;
    }

    .loading-elipsis {
        overflow: hidden;
        -webkit-animation: ellipsis steps(4, end) 900ms infinite;
        animation: ellipsis steps(4, end) 900ms infinite;
        content: "\2026";
        /* ascii code for the ellipsis character */
        width: 0px;
    }
}

@media (min-width: 768px) {

    .infinite-scroll,
    .media-list {

        .project-item,
        .project-item-skeleton {
            @include flex-grid-col(6, 12, 20);
        }
    }
}

@media (min-width: 1024px) {

    .infinite-scroll,
    .media-list {

        .project-item,
        .project-item-skeleton {
            @include flex-grid-col(4, 12, 20);
        }
    }
}

@media (min-width: 1440px) {

    .infinite-scroll,
    .media-list {

        .project-item,
        .project-item-skeleton {
            @include flex-grid-col(3, 12, 20);
        }
    }
}

@media (min-width: 2000px) {

    .infinite-scroll,
    .media-list {

        .project-item,
        .project-item-skeleton {
            @include flex-grid-col(2, 12, 20);
        }
    }
}

@media (min-width: 2560px) {

    .infinite-scroll,
    .media-list {

        .project-item,
        .project-item-skeleton {
            @include flex-grid-col(1.5, 12, 20);
        }
    }
}

@media (min-width: 3400px) {

    .infinite-scroll,
    .media-list {

        .project-item,
        .project-item-skeleton {
            @include flex-grid-col(1.2, 12, 20);
        }
    }
}