.not-found-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    .not-found-body {
        width: 50%;
        max-height: 100%;
        height: 100%;

        .not-found-share-logo {
            margin: 10% 0 0 10%;
            height: 10%;
            > img {
                height: 100%;
            }
        }

        .not-found-form {
            margin: 16px 0;
            display: flex;
            flex-direction: column;
            height: min-content;
            max-height: 100%;
            justify-content: center;
            text-align: center;
            gap: 4px;
            padding: 0 10%;

            .not-found-graphic {
                height: 180px;
                > img {
                    height: 100%;
                }
            }

            .not-found-form-actions {
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
        }

        .not-found-footer {
            text-align: center;
            margin-top: auto;
            margin-bottom: 16px;
            max-height: 100%;
        }
    }

    .not-found-image-container {
        width: 50%;

        .not-found-image {
            height: 100%;
            clip-path: polygon(100% 0, 100% 100%, 100% 100%, 0 100%, 13% 0);
        }
    }
}

@media (max-width: 800px) {
    .not-found-container {
        .not-found-image-container {
            display: none;
        }

        .not-found-body {
            width: 100%;
        }
    }
}
