@import "styles/mixins.scss";
@import "styles/colors.module.scss";

.panel {
    width: rem(300);
}

.icon-button {
    width: rem(28);
    height: rem(28);
}

.class-icon {
    margin-right: rem(10);
}

.actionIcon {
    width: rem(24);
    height: rem(24);
    padding: 0;

    img {
        width: rem(20);
        height: rem(20);
    }
}

.console-container {
    padding: rem(15) 0;

    .icon-node {
        margin-right: 0;
        width: rem(20);
        opacity: 0;
        transition: .2s opacity;
    }

    &:hover {
        .icon-node {
            opacity: 1;
        }
    }

    .group-node {
        margin-left: 0;
    }

    .content-node {
        padding: rem(4) rem(15);
        position: relative;
        display: flex;

        &.focused-node {
            background-color: transparent;
        }

        &.selected-node {
            background-color: rgb(249, 175, 8, .2) !important;
        }

        &.selected-root-node {
            background-color: rgb(249, 175, 8, .5) !important
        }

        .action-buttons {
            display: flex;

            .icon-button {
                opacity: 0;
                transition: .2s opacity;
            }

            .visibility-button {
                @extend .icon-button;
            }
        }

        &:hover {
            background-color: rgb(216, 216, 216, .4) !important;

            .icon-button {
                opacity: 1;
            }
        }
    }
}

.node-label-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: rem(28);

    .node-label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .searched-term {
            background-color: #f6a81c;
            padding: rem(2) rem(1);
            border-radius: rem(3);
        }
    }

    &.node-label-container-invisible {
        .node-label-wrapper {
            opacity: 50%;
        }

        .action-buttons {
            .visibility-button {
                opacity: 1;
            }
        }
    }

    &.node-label-container-no-geo {
        opacity: 35%;

        .action-buttons {
            display: none;
        }
    }
}

.node-label-wrapper {
    display: flex;
    max-width: calc(100% - rem(56));
}