.tooltip {
    width: 100%;
    margin: auto;
    padding: 25px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.container {
    position: relative;
}

.dummy {
    padding-top: 100%;
}

.progress {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.video {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.video video {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    object-fit: cover !important;
}

.requestBox {
    pointer-events: all;
    cursor: pointer;
    position: absolute;
    top: 0;

    h3 {
        color: rgba(0, 0, 0, 0.6);
    }
}

.flipButton {
    position: absolute !important;
    left: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 1;
}
