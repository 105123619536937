.grid {
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loginSidePanel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.image {
    object-fit: cover;
    height: 100%;
    width: 100%;
    min-height: 700px;
}

.clipi {
    background-size: cover;
    background-color: rgba(206, 158, 63, 0.4);
    background-image: url("../assets/createAccount.jpg");
    background-blend-mode: screen;
    clip-path: polygon(100% 0, 100% 100%, 100% 100%, 0 100%, 13% 0);
}

/*

    xs, extra-small: 0px
    sm, small: 600px
    md, medium: 900px
    lg, large: 1200px
    xl, extra-large: 1536px

*/
@media (max-width: 900px) {
    .responsive {
        display: none;
    }
}