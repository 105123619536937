.filterPopper{
    z-index: 999;
}

.checkBoxField{
    display: flex;
}

.filterPaper{
    padding: 16px;
    justify-content: center;
}

.checkBoxLabel{
    margin-top: auto;
    margin-bottom: auto;
}