.root{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.separator{
    font-size: 1em; 
    font-weight: 900;
    color: #b4b4b4;
}