.viewer-settings-container {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    z-index: 1;

    .viewer-settings-button {
        width: 30px;
        height: 30px;
        min-width: auto;
        background-color: #fff;
        transition: .3s background-color;
        border-radius: 3px;
        margin: .5rem;
        box-shadow: 0 0 3px 0 rgba($color: #000000, $alpha: .3);
        padding: 0;

        &:hover {
            background-color: #ececec;
        }
    }

    .viewer-settings-menu {
        max-height: 0;
        overflow: hidden;
        background-color: #fff;
        margin: .5rem .5rem 0 .5rem;
        box-shadow: 0 0 4px 0 rgba($color: #000000, $alpha: .15);
        border-radius: 5px;
        transition: max-height .3s;
        max-width: 272px;

        .viewer-settings-header {
            background-color: rgba(#D8D8D8, $alpha: .4);
            padding: .3rem .6rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .viewer-settings-body {
            padding: .6rem;

            .switch-list-input {
                display: flex !important;
                align-items: center;
                flex-direction: column;
                padding-bottom: 5px;
                padding: 0 2px 0 0;

                label {
                    margin: 0;
                    font-weight: 500 !important;
                    margin-bottom: -8px !important;
                }
            }

            .input-container {
                display: flex !important;
                align-items: center;

                label {
                    font-weight: 500 !important;
                    flex-basis: 100%;
                }
            }

            .checkbox-container {
                label {
                    font-weight: 500 !important;
                }
            }

            .color-picker-preview {
                width: 100%;
                height: 37.12px;
                margin: 5px 0;
                border-radius: 4px;
                cursor: pointer;
                box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
            }

            .popover {
                position: absolute;
                z-index: 2;
                bottom: calc(-100% + 80px);
                right: 0;
            }

            .cover {
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            .layers-container {
                display: flex;
                flex-wrap: wrap;
                margin-top: 8px;
            }
        }

        &.menu-open {
            max-height: 728px
        }
    }
}